.login-back-button {
  position: absolute;
  top: 20px;
  left: 40px;
  color: #fff;
  font-size: 50px;
  text-decoration: none;
  z-index: 10; /* Ensure it appears above other elements */
}

.container-login {
  background-color: #ffffff5e;
  height: 400px;
  width: 500px;
  margin-left: 100px;
  align-items: center;
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(67, 25, 92, 0.8);
}

.login-welcome-message {
  font-size: 80px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 50px;
  margin-top: -80px;
  margin-left: 100px;
  line-height: 80px;
}

.login-sign-up-text {
  font-size: 20px;
  color: #042440;
  text-align: center;
  margin-top: -20px;
  margin-left: -930px;
  margin-bottom: 50px;
}

.login-success-message {
  color: #e39fa9;
  margin-left: 100px;
  margin-bottom: 40px;
  margin-top: -40px;
}

.login-error-message {
  color: #e39fa9;
  margin-left: 100px;
  margin-bottom: 40px;
  margin-top: -40px;
}
.login-sign-up-text a {
  color: #ffffff;
  text-decoration: none;
  margin-left: 10px;
}

.login-sign-up-text a:hover {
  text-decoration: underline;
  color: #e39fa9;
}

.login-outer-container {
  padding: 200px;
  position: relative;
  z-index: 0;
}

.login-outer-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  z-index: -1;
}

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #7c459cbc !important; /* Adjust the color and opacity as needed */
  z-index: -1;
}

.login-background {
  height: 550px;
}
.LI-form-group {
  position: relative;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-top: 20px;
}

.LI-form-input {
  padding: 10px;
  border: transparent !important;
  font-size: 16px;
  margin-top: 5px;
  background-color: white !important;
  color: #042440;
  width: 100%;
  transition: border-color 0.3s ease;
  border-radius: 5px !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.LI-form-input:focus ~ .LI-form-label,
.LI-form-input:not(:placeholder-shown) ~ .LI-form-label {
  top: -20px; /* Move label up when input is focused or has content */
  font-size: 12px; /* Shrink font size when label moves up */
  color: #042440; /* Change label color when active */
}

.LI-form-label {
  position: absolute;
  top: 20px; /* Initial position to start above the input */
  left: 10px;
  color: #042440;
  font-size: 16px; /* Start with a smaller font size */
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  pointer-events: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.forgot-password {
  margin-left: 300px;
  margin-top: -10px;
  margin-bottom: 10px;
  color: #e39fa9 !important;
}

.password-toggle-icon {
  position: absolute;
  top: 50%; /* Adjust based on the desired vertical position */
  right: 20px; /* Adjust based on where you want the icon */
  transform: translateY(-50%); /* Vertically center the icon */
  cursor: pointer;
  z-index: 1000;
}

.LI-button {
  padding: 10px 215px !important;
  width: 250px;
  background-color: #e39fa9;
  color: #040400;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
}

.LI-button:hover {
  background-color: #7c459cbc;
  color: #ffffff;
}

.login-close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-close-button:hover {
  background: darkred;
}

/* authentication */
.Authentication__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Authentication__Content {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.Authentication__Icon {
  font-size: 70px;
  color: #9a6cb4; 
  margin-bottom: 20px;
}

.Authentication__Title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.Authentication__Subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.Authentication__CodeWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Authentication__CodeInput {
  width: 50px;
  height: 60px;
  font-size: 24px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  outline: none;
  transition: border-color 0.3s ease;
}

.Authentication__CodeInput:focus {
  border-color: #f5b63a;
}

.Authentication__Button {
  background-color: #9a6cb4;
  color: #fff;
  padding: 15px 40px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}

.Authentication__Button:hover {
  background-color: #e39fa9;
}

.Authentication__SubmitButton {
  margin-top: 20px;
}

.Authentication__RememberDevice {
  display: flex;         
  align-items: center;   
  margin-top: 10px;    
}

.Authentication__Checkbox {
  margin-right: 8px;   
  margin-top: -5px;  
}

.Authentication__CheckboxLabel {
  font-size: 14px;     
  color: #333; 
}


