  
.bellytalk-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ffffff;
    width: 100%;
    height: 100px;
  }
  
  .bellytalk-title-logo {
    font-size: 30px;
    font-weight: bold;
    color: #7c459c;
    margin-left: 200px;
  }
  
  .bellytalk-label {
    font-size: 16px;
    color: #603830;
    margin-right: 15px;
  }
  
  .bellytalk-search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 30%;
    border-radius: 20px;
    margin-right: 200px;
  }
  
  .bellytalk-search {
    border-radius: 20px;
    border: 1px solid #042440;
    outline: none;
    text-indent: 40px;
    z-index: 1;
    height: 40px;
    width: 500px;
  }
  
  .bellytalk-search::placeholder {
    color: #042440;
  }
  
  .bellytalk-search-icon {
    position: absolute;
    left: 15px;
    font-size: 16px;
    color: #042440 !important;
    z-index: 1000;
  }

  .SP-back-button {
    background-color: transparent; 
    color: #042440; 
    border: none; 
    border-radius: none;
    font-size: 20px;
    cursor: pointer; 
    margin-top: 10px; 
    align-self: center;
    margin-left: 180px;
  }

  .SP-back-button svg{
    font-size: 20px;
    margin-top: 5px;
  }
  

  .saved-posts-page {
    display: flex;
    background-color: #f2eeee !important;
    height: 100vh;
    flex-direction: column;
  }
  
  .saved-posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Aligns items to the center */
    width: 100%;
    padding: 0 20px;
    gap: 20px; /* Controls the spacing between cards */
  }
  
  .saved-post-card {
    display: flex;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: calc(33.333% - 20px); /* Three cards per row with gap */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  
  .post-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .post-details {
    padding: 10px;
    flex: 1;
  }
  
  .post-details h3 {
    font-size: 18px;
    margin: 0 0 8px;
  }
  
  .post-meta {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
  }
  
  .news-category {
    background-color: #eef;
    padding: 2px 6px;
    border-radius: 4px;
  }
  
  .post-reactions {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .post-reactions span {
    display: flex;
    align-items: center;
  }
  
  .post-reactions span svg {
    margin-right: 4px;
    color: #7c459c;
  }

  /* post modal */
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .savedpost-feed-item {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .savedpost-post-header {
    display: flex;
    align-items: center;
    gap: 15px; 
    margin-bottom: 15px; 
    object-fit: cover;
  }
  
  .savedpost-avatar-overlay {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .savedpost-fullname {
    font-size: 18px;
    font-weight: bold;
  }

  .savedpost-divider {
    border: 0;
    height: 1px;
    background: #e0e0e0;
    margin: 15px 0;
  }
  
  .savedpost-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 650px;
  }
  
  