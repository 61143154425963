.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #ffffff;
}

.menu-items {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.menu-items li {
  margin-right: 20px;
  color: #603830;
}

.footer-line {
  width: 100%;
  border-top: 1px solid #e39fa9;
  margin-top: 20px;
  margin-bottom: 20px;
}

.copyright {
  font-size: 12px;
  color: #603830;
}
