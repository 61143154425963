

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FCFAF2;
  padding: 20px;
  flex-wrap: wrap;
}


.header-center {
  position: absolute;
  left: 50%;
  top: 10px; /* Adjust the top position as needed */
  transform: translateX(-50%);
  z-index: 1;
}

.header-right {
  text-align: right;
}

.header-right .nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 100px;
  font-size: 20px;
  color: #E39FA9;
  display: flex;
  align-items: center;
}

.header-right .nav-links li {
  display: inline;
  margin-right: 100px;
  color: #E39FA9;
}

.header-left .nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 200px;
  font-size: 20px;
  color: #E39FA9;
}

.header-right .nav-links li a {
  text-decoration: none;
  color: #E39FA9;
  font-weight: bold;
}


.bt-contact{
  padding: 10px 20px;
  background-color: #ffffff;
  color: #c98f8f;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.sign-up-button {
  padding: 10px 40px;
  background-color: #E39FA9;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
}

.sign-up-button:hover {
  color: #161111;
}

.logo {
  width: 120px;
  height: 120px;
  border-radius: 50%; /* Make the logo circular */
}

/* What we do Section */

.flower-icon1 {
  color: #7c459c !important; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the size as needed */
  height: 100px;
  width: 100px;
  margin-left: 900px;
  margin-top: -20px;
}

.what-we-do {
  position: relative;
  padding: 50px 0;
  overflow: hidden; /* Ensure content inside this section stays within boundaries */
  background-color: transparent !important ;
  background-size: cover; /* Adjust as needed */
  margin-top: 10px;
  
}

.what-we-do:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  z-index: -1; /* Ensure the blur overlay is behind other content */
}



.what-we-do .content-wrapper {
  position: relative;
  z-index: 1;
}

.what-we-do .service h3 {
  margin-bottom: 10px;
  color:#ffffff !important;
}
.what-we-do .service p {
  margin-top: 10px; /* Adjust the top margin */
  margin-bottom: 20px; /* Adjust the bottom margin */
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  color: #ffffff !important;
}

.what-we-do .service2 h3 {
  margin-bottom: 10px;
  color: #ffffff !important;
}
.what-we-do .service2 p {
  margin-top: 10px; /* Adjust the top margin */
  margin-bottom: 20px; /* Adjust the bottom margin */
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  color: #ffffff !important;
}

.what-we-do h2{
  color: #E39FA9;
  margin-top: -10px;
  margin-left:700px;
  font-size: 5rem;
  font-style: oblique;
  margin-bottom: 50PX;
}

.what-we-do .services-container {
  display: flex;
  flex-direction: column; /* Arrange services in a column */
  margin-left: 10px;
}

.what-we-do .row {
  display: flex;
  justify-content: center; /* Center services horizontally */
}

.what-we-do .service {
  position: relative; /* Ensure the .hover-image is positioned relative to the .service */
  margin: 10px;
  border-radius: 10px;
  height: 200px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center all content horizontally and vertically */
  overflow: hidden; /* Hide overflow content */
  margin-top: -10px;
  background-color: #9a6cb4 !important;
}

.what-we-do .service2 {
  position: relative; /* Ensure the .hover-image is positioned relative to the .service */
  margin: 10px;
  border-radius: 10px;
  height: 200px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center all content horizontally and vertically */
  overflow: hidden; /* Hide overflow content */
  margin-top: 50px;
  background-color: #c98f8faf;
}

.what-we-do .service2 img {
  width: 50px;
  margin-top: 0px !important;
}


.what-we-do .service2:hover .hover-image {
  display: flex; /* Show the hover image when hovering over the service */
}

.what-we-do .service img {
  width: 50px;
  color: #603830;
  margin-top: 20px;
}


.what-we-do .service:hover .hover-image {
  display: flex; /* Show the hover image when hovering over the service */
}

.what-we-do .hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: none; /* Initially hide the hover image */
  justify-content: center;
  align-items: center;
}

.what-we-do .hover-image img {
  width: 500px;
  height: 300px;
  margin: auto;
}

/* Hero section styles */
.hero {
  position: relative;
  height: 800px;
  overflow: hidden;
  
}

.video-container {
  border-radius: 50px; /* Add border radius to the container */
  overflow: hidden; /* Ensure the border radius applies correctly */
  background-color: transparent !important;

}


.hero-video {
  width: 100%;
  height: 100%;
  transform: translate(0%, -20%);
  margin-bottom: auto;
  opacity: 0.7;
}

.hero-content-left {
  position: absolute;
  top: 50%;
  left: 20px; /* Adjust left margin */
  transform: translateY(-50%);
  z-index: 1;
}

.hero-content {
  text-align: left; /* Align text to left */
  background-color:transparent !important; 
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
}

.hero-content h1 {
  font-size: 2.5rem;
  color: #ffffff !important;
}

.hero-content p {
  font-size: 1.2rem;
  color: #ffffff !important;
}

.download-button {
  padding: 20px 50px;
  background-color: #7c459c !important;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px; /* Add some spacing */
  border-radius: 25px; /* Make it round */
  margin-left: 10%;
  font-size: 1rem;
}


.download-button:hover {
  background-color: #042440 !important;
}

.hero-image {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero h1 {
  font-size: 9rem;
  font-style: italic;
  color: #ffffff;
}

.hero p {
  font-size: 1.5rem;
  color: #ffffff;
}

/* about us section styles */
.about-us {
  padding: 80px 0; /* Adjust the padding as needed */
  background-color:transparent !important;
  margin-top: -300px;
  z-index: 1;
  height: 700px;
  
}

.about-us::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust the height of the line */
  background-color: transparent !important; /* Color of the line */
  margin-top: 840px;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the content */
  height: 800px;
}

.about-text {
  margin-top: 320px;
  flex: 1;
  padding: 0 20px; /* Add padding to the text content */
  color: #042440 !important; /* Text color */
}

.about-text hr {
  border: 0;
  height: 5px;
  background-color: #0000005b; /* Adjust color as needed */
  margin-bottom: -30px; /* Adjust margin-bottom to control spacing */
  width: 200px;
  border-radius: 20px;
  margin-top: -100px !important;
}

.about-text h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: #E39FA9 !important;
  font-style: italic;
}

.about-text h2 {
  font-size: 5rem;
  margin-bottom: 10px;
  text-align: center;
  font-style: italic;
  color: #7c459c !important;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 20px; /* Add some space between paragraphs */
  text-align: center;
  padding: 5px;
  width: 800px;
  margin-left:180px;
}

.download-button1 {
  padding: 20px 40px;
  font-size: 16px;
  background-color: #E39FA9;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 20px; /* Adjust the margin as needed */
  display: block; /* Make the button a block element */
  margin-left: 470px;
  border-radius: 50px;
}

.download-button1:hover {
  background-color: #7c459c !important; /* Darker blue on hover */
}

a {
  text-decoration: none;
}

/* topic-of-interest styles */

.flower-icon {
  color: #7c459c !important; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the size as needed */
  height: 100px;
  width: 100px;
  margin-left: 50px;
  margin-top: 90px !important;
}

.topic-of-interest {
  padding: 50px 0;
  background-color: transparent !important;
  margin-top: -50px;
  height: 900px;
}



.topic-of-interest h2 {
  margin-top: -20px;
  padding: 50px 0;
  text-align: center;
  font-size: 5rem;
  color: #7c459c !important;
  font-style:italic !important;
}

.topic-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.topic-box {
  flex: 0 1 calc(20% - 20px); /* Adjust width and spacing */
  width: 200px;
  text-align: center;
  margin-bottom: 30px;
  margin-left: 0px;
}

.topic-box img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.topic-text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: lighter;
  font-style: italic;
  color: #042440;
}

/* topic-of-interest styles */


/* Contact-Landing specific styles */
.contactLP-container {
padding: 50px 0;
background-color: transparent;
font-family: 'Playfair Display', serif;
margin-top: -20px;
}

.contactLP-heading {
text-align: center;
margin-bottom: 30px;
border: 2px solid #E39FA9;
border-radius: 50px;
padding: 10px;
margin-left: 830px;
margin-right: 780px;
font-size: 0.8rem;
font-weight: normal;
background-color: #E39FA9;
color: #ffffff;
margin-top: 5px;
}

.contactLP-box {
max-width: 600px;
margin: 0 auto;
padding: 20px;
}

.contactLP-title {
text-align: center;
margin-bottom: 20px;
font-size: 3rem;
font-weight: bold;
margin-top: -30px;
margin-left: 50px;
color: #7c459c !important;
}

.contactLP-box form {
display: flex !important;
flex-wrap: wrap !important;
justify-content: space-between !important;
}

.contactLP-form-group {
flex: 0 0 45% !important;
margin-bottom: 20px !important;
}

label {
display: block;
margin-bottom: 5px;
color: #042440 !important;
}

.contactLP-input {
width: 100% !important;
padding: 20px !important;
border: 1px solid #ccc !important;
border-radius: 5px !important;
background-color: #e39fa977 !important;
margin-bottom: -50px !important;
}

.contactLP-textarea {
margin-top: 5px !important;
width: 635px;
background-color: #e39fa977 !important;
border-radius: 5px !important;
border: 1px solid #ccc !important;
height: 200px;

}

.contactLP-button {
background-color: #E39FA9;
padding: 15px 40px;
color: white;
border: #c98f8f;
margin-left: 220px;
border-radius: 25px;
font-family: 'Playfair Display', serif;
cursor: pointer;
}

.contactLP-button:hover {
background-color: #9a6cb4;
color: #ffffff;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .hero-content-left {
    left: 10px; 
    margin-top: -150px;
  }

  .hero-content h1 {
    font-size: 2rem; 
  }

  .hero-content p {
    font-size: 1rem; 
  }

  .download-button {
    padding: 15px 30px; 
    font-size: 0.9rem; 
    margin-top: -50px;

  }
  .about-content{
    margin-top: -300px;
  }

  .about-text h2 {
    font-size: 4rem; /* Adjust font size for larger tablets */
  }

  .about-text h1 {
    font-size: 1.8rem; /* Adjust font size */
  }

  .about-text p {
    font-size: 1rem; /* Smaller font size for readability */
    margin: 0 10px; /* Add some margin */
  }

  .flower-icon1 {
    font-size: 6vw; 
    margin-left: auto; 
    margin-right: auto; 
  }

  .what-we-do h2 {
    font-size: 4rem; /* Adjust font size for better readability */
    margin-bottom: 30px; /* Reduce bottom margin for spacing */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .what-we-do .service, .what-we-do .service2 {
    width: 90%; /* Use a percentage width to allow flexibility */
    max-width: 500px; /* Limit max width for larger screens */
    margin: 10px auto; /* Center services with automatic left and right margins */
  }

  .what-we-do .services-container {
    flex-direction: column; /* Stack services vertically */
    align-items: center; /* Center the services horizontally */
  }

  .what-we-do .row {
    flex-direction: column;  /* Stack service items vertically */
    align-items: center;      /* Center items horizontally */
    width: 100%;             /* Use full width */
  }

}

@media (max-width: 768px) {
  .hero {
    height: 600px; /* Reduce height for smaller screens */
  }

  .hero-content-left {
    left: 5px; /* Adjust left margin */
  }

  .hero-content h1 {
    font-size: 2rem; /* Further adjust font size */
  }

  .hero-content p {
    font-size: 1rem; /* Further adjust font size */
  }

  .download-button {
    padding: 10px 20px; /* Adjust button padding */
    font-size: 0.8rem; /* Adjust font size for smaller screens */
  }
  .about-text {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .about-us{
    margin-top: -400px;
  }

  .about-text {
    padding: 20px 15px; /* Adjust padding for smaller screens */
    overflow: hidden; /* Prevent overflow */
  }

  .about-text h2 {
    font-size: 2.5rem; /* Further reduce heading size */
  }

  .about-text h1 {
    font-size: 2rem; /* Further reduce heading size */
  }

  .about-text p {
    font-size: 0.8rem; 
    width: 400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .download-button1 {
    display: none;
  }

  .flower-icon1 {
    font-size: 2rem;
    text-align: center;
    align-items: center;
    display: flex; /* Enable flexbox */
    justify-content: center;
  }

  .what-we-do{
    margin-top: -350px;
  }

  .what-we-do h2 {
    font-size: 3rem; /* Increase font size for larger screens */
  }

  .what-we-do .service,
  .what-we-do .service2 {
    width: 400px; /* Increase width for tablets */
  }

  .topic-of-interest h2{
    font-size: 3rem;
  }

  .contactLP-heading{
    margin-top: -400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
}

@media (max-width: 480px) {
  .hero {
    height: 500px; /* Further reduce height for very small screens */
  }

  .hero-content-left {
    left: 0; /* Center content */
    padding: 10px; /* Add padding */
  }

  .hero-content h1 {
    font-size: 1.2rem; /* Adjust font size for small screens */
  }

  .hero-content p {
    font-size: 0.8rem; /* Adjust font size for small screens */
  }

  .download-button {
    padding: 10px 15px; /* Adjust button padding */
    font-size: 0.7rem; /* Adjust font size for small screens */
    margin-left: 0; /* Center button */
  }
  .about-us {
    padding: 40px 0; /* Further reduce padding */
  }

  .about-text h2 {
    font-size: 2.5rem; /* Further reduce heading size */
  }

  .about-text h1 {
    font-size: 1.3rem; /* Further reduce heading size */
  }

  .about-text p {
    font-size: 0.8rem; /* Further reduce paragraph size */
  }

  .download-button1 {
    padding: 10px 20px; /* Adjust button padding */
    font-size: 12px; /* Adjust button font size */
  }
  .what-we-do h2 {
    font-size: 3rem; /* Reduce font size for mobile */
  }

  .what-we-do .service,
  .what-we-do .service2 {
    width: 100%; /* Full width on mobile */
    height: auto; /* Allow height to adjust */
  }

  .what-we-do .hover-image img {
    width: 100%; /* Full width for hover images on mobile */
  }

  .flower-icon1 {
    font-size: 10vw; 
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .what-we-do h2 {
    font-size: 8vw; /* Increase size for mobile */
  }
}