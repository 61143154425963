.consultant-dashboard-container {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.consultant-main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.consultant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.consultant-search-input {
  width: 120% !important;
  padding: 9px !important;
  font-size: 14px;
  border: none !important;
  border-radius: 10px !important;
  outline: none;
  margin-left: 50px !important;
  transition: border-color 0.3s ease;
  background-color: #ffffff !important;
  text-indent: 25px;
}

.consultant-search-input::placeholder {
  color: #042440;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.consultant-search-icon {
  position: absolute;
  left: 280px;
  top: 30px;
  font-size: 16px;
  color: #042440;
  z-index: 1000;
}

.consultant-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consultant-profile-icons {
  display: flex;
  align-items: center;
  margin-right: 10px;
  z-index: 1001; 
}

.consul-profile-icon,
.consul-notification-icon {
  font-size: 30px;
  color: #7c459c; 
}

.consul-profile-icon {
  margin-right: 10px; 
}

.consul-notification-icon {
  margin-left: 10px; 
}


.consultant-profile-info {
  position: fixed; 
  right: 20px; 
  top: 20px;
  display: flex;
  align-items: center; 
  z-index: 1000; 
  background-color: transparent; 
}

.consultant-profile-text {
  display: flex;
  flex-direction: column; 
  text-align: right; /* Align text to the right */
  margin-right: 10px; /* Space between text and image */
}

.consultant-profile h1 {
  margin: 0; 
  font-size: 18px; 
}

.consultant-profile p {
  margin: 0; 
  font-size: 14px; 
  color: #666; 
}

.consultant-profile-image {
  border-radius: 50%; 
  width: 50px; 
  height: 50px; 
  object-fit: cover;
}


.consultant-greeting-section {
  display: flex;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
  height: 150px;
  width: 950px;
  margin-top: 80px;
  align-items: center;
  justify-content: space-between; 
  padding: 30px;
  background-color: white; 
}

.consultant-greeting-text {
  max-width: 50%; /* Restricts the text width */
  margin-left: 20px;
}

.consultant-greeting-text h2 {
  margin: 0;
  font-size: 26px;
  color: #333;
}

.consultant-greeting-text h3 {
  margin: 5px 0 0 0;
  font-size: 30px;
  color: #7c459c;
}

.consultant-greeting-text p {
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #666;
}

.consultant-greeting-image {
  max-width: 100%;
  margin-top: -70px;
  margin-right: 20px;
}

.consultant-greeting-image img {
  width: 300px; /* Makes the image responsive */
  height: auto;
  border-radius: 8px; /* Optional: add a border-radius to the image */
}

.consultant-weekly-reports {
  width: 950px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 80px;
}

.consultant-report-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 18%;
  height: 150px;
  flex-direction: column;
  align-items: center;
}

.consul-icon {
  font-size: 24px; /* Adjust icon size */
  margin-bottom: 10px; /* Space between icon and text */
  margin-top: 30px;
}

.consul-text {
  font-size: 16px; 
  margin-bottom: 5px; 
}

.consul-number {
  font-size: 24px; 
  font-weight: bold; 
}

.consultant-report-card.total-patients {
  background-color: #9a6cb4;
  color: white;
}

.consultant-patients-table {
  background-color: none;
  padding: 20px;
  border-radius: 10px;
  width: 950px;
  margin-left: 40px;
  margin-top: -20px;
}

.patients-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

.view-all-patients {
  background-color: #9a6cb4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  margin-left: 10px;
}

.view-all-patients:hover {
  text-decoration: none;
  background-color: #7c459c;
}

.consultant-patients-table table {
  width: 95%;
  border-collapse: separate;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.consultant-patients-table th {
  background-color: #9a6cb4 !important;
  font-weight: bold;
  color: #ffffff;
  padding: 12px 15px;
  text-align: left;
}

.consultant-patients-table td {
  padding: 12px 15px;
  color: #333;
  text-align: left;
  background-color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}

.consultant-right-sidebar {
  width: 450px;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.consultant-schedule-calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.consultant-schedule-calendar h3 {
  margin-bottom: 10px;
  color: #042440;
}

.consultant-schedule-calendar .calendar {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
}

.consultant-notifications {
  margin-bottom: 20px;
  height: 400px; 
  overflow-y: scroll; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.consultant-notifications::-webkit-scrollbar {
  display: none; 
}

.consultant-notifications h3 {
  margin-bottom: 20px;
  color: #042440;
}

.notifications-list .notification-item {
  background-color: none !important;
  padding: 10px;
  border: 1px solid #9a6cb4;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #042440;
  margin-left: -100px;
}

/* Custom styles for the calendar */
.consul-custom-calendar {
  width: 100%;
  max-width: 350px;
  background-color: none !important;
  margin-left: 50px;
  border: none !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.consul-custom-calendar .react-calendar__tile {
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.consul-custom-calendar .react-calendar__tile:enabled:hover,
.consul-custom-calendar .react-calendar__tile:enabled:focus {
  background-color: #9a6cb4;
  color: #fff;
}

.consul-custom-calendar .react-calendar__tile--active {
  background-color: #e39fa9;
  color: #042440 !important;
}

.consul-custom-calendar .react-calendar__navigation button {
  color: #7c459c;
  font-size: 15px;
}

.consul-custom-calendar .react-calendar__navigation button:disabled {
  background-color: #f0f4f7;
}

.consul-custom-calendar .react-calendar__month-view__weekdays {
  font-weight: bold;
  color: #7c459c;
}

.consul-custom-calendar .react-calendar__month-view__days__day--weekend {
  color: #e39fa9;
}

/* add patient form */
.add-patient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-patient-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.add-patient h2 {
  margin-bottom: 1rem;
}

.add-patient-form {
  margin-bottom: 1rem;
}

.add-patient-form label {
  display: block;
  margin-bottom: 0.5rem;
}

.add-patient-form input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-patient-submit {
  margin: 10px 0;
  padding: 10px;
  width: 150px;
  background-color: #9a6cb4;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 70px;
}

.add-patient-cancel {
  margin: 10px 0;
  padding: 10px;
  width: 100px;
  background-color: #e39fa9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}
