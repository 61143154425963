/* Container */
.CPM-container {
  display: flex;
  height: 100vh;
  width: 90%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* Main Section */
.CPM-main-section {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.CPM-add-btn {
  background-color: #9a6cb4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  margin-right: 350px;
  margin-top: -40px;
  margin-left: -200px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.CPM-add-btn:hover {
  background-color: #7c459c;
}

.CPM-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 1280px;
  margin-top: -20px;
}

.CPM-user-profile {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 30px;
}

.CPM-user-profile h1 {
  margin: 0; /* Remove default margin */
  font-size: 18px; /* Adjust the size as needed */
  margin-right: 70px;
}

.CPM-user-profile p {
  margin: 0;
  font-size: 14px;
  color: #666;
  margin-right: 70px;
}

.CPM-user-profile img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 290px;
  margin-top: -45px;
}

/* Filter Options */
.CPM-filter-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.CPM-filter-container {
  display: flex;
  align-items: center;
  margin-top: -50px;
  margin-right: 40px;
}

.CPM-filter-container label {
  margin-right: 8px; /* Adjust the spacing between the label and select */
  margin-top: 8px;
}

.CPM-filter-container select {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 150px;
}

.CPM-toggle-select {
  display: flex;
  align-items: center;
  margin-left: 180px;
  margin-top: -50px;
}

.CPM-toggle-select label {
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
}

.CPM-total-users {
  font-weight: bold;
  color: #ccc;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
}

.CPM-select {
  width: 150px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* User Table */
.CPM-user-table {
  width: 95%;
  border-collapse: separate;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.CPM-user-table-container {
  width: 95%; 
  height: 700px; 
  overflow-y: auto; 
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.CPM-user-table-container::-webkit-scrollbar {
  display: none; 
}


.CPM-user-table thead {
  background-color: #ffffff;
  position: sticky; /* Keeps header fixed */
  top: 0;
  z-index: 2; /* Ensures header stays above the body content */
}

.CPM-user-table th {
  background-color: #9a6cb4 !important;
  font-weight: bold;
  color: #ffffff;
  padding: 12px 15px;
  text-align: left;
}

.CPM-user-table td {
  padding: 12px 15px;
  color: #333;
  text-align: left;
  background-color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}

.CPM-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  object-fit: cover; /* Ensures the image covers the area without stretching */
}

/* Operation Buttons */
.CPM-operation-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 25px; /* Adjust icon size */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.CPM-delete-btn {
  color: #7c459c;
}

.CPM-operation-btn {
  color: #7c459c;
}

/* Type Buttons */
.CPM-type-buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 40px;
}

.CPM-type-button {
  padding: 20px 70px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  color: #040404;
  font-size: 18px;
}

.CPM-type-button.active {
  border-bottom: 3px solid #e39fa9; /* Add a line below the button */
  color: #040404; /* Optional: Change the text color to match the line */
}

.CPM-type-button:hover {
  background-color: #7c459c;
  color: #ffffff;
}

/* View Label */
.CPM-view-label {
  margin-bottom: 10px;
  text-align: left;
  margin-top: 10px;
  margin-left: 40px;
}

.CPM-view-label h2 {
  font-size: 30px;
  font-weight: bold;
  color: #4a4a4a;
  margin-top: 10px;
}

.CPM-status-select {
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.CPM-status-select:focus {
  border-color: #007bff;
  outline: none;
}

.CPM-status-select option {
  padding: 5px;
  background-color: #fff;
  color: #333;
  width: 250px;
}

.CPM-user-table th:nth-child(1), 
.CPM-user-table td:nth-child(1),
.CPM-user-table th:nth-child(2), 
.CPM-user-table td:nth-child(2),
.CPM-user-table th:nth-child(3), 
.CPM-user-table td:nth-child(3) {
  text-align: center;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Modal Photo */
.modal-photo {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

/* General Body and Font Styles */
body {
  font-family: "Lucida Sans", "Lucida Grande", sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.Pi-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Sidebar */
.Pi-sidebar {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Top Section: Patient Info and Vitals */
.Pi-top-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Pi-patient-info {
  display: flex;
  align-items: center;
}

.Pi-patient-info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.Pi-vital-signs {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.Pi-vital-signs .Pi-vital {
  text-align: center;
}

.Pi-vital-signs .Pi-vital h3 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.Pi-vital-signs .Pi-vital p {
  font-size: 14px;
  color: #666;
}

/* Main Content: Grid Layout for Panels */
.Pi-main {
  flex: 3; /* Takes up 3/4 of the space */
  margin-right: 20px; /* Space between main content and right panels */
}

/* Task Section (Full Width) */
.Pi-outstanding-tasks {
  grid-column: 1 / span 3;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Pi-outstanding-tasks table {
  width: 100%;
  border-collapse: collapse;
}

.Pi-outstanding-tasks th,
.Pi-outstanding-tasks td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.Pi-outstanding-tasks th {
  background-color: #f9f9f9;
  font-weight: bold;
}

/* Panels: Encounters, Documents, Problem List */
.Pi-panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Pi-past-encounters,
.Pi-documents,
.Pi-problem-list {
  display: flex;
  flex-direction: column;
}

.Pi-past-encounters h4,
.Pi-documents h4,
.Pi-problem-list h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.Pi-past-encounters ul,
.Pi-documents ul,
.Pi-problem-list ul {
  list-style-type: none;
  padding: 0;
}

.Pi-past-encounters li,
.Pi-documents li,
.Pi-problem-list li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Right-Side Panels: Readmission Risk, Allergies, Medication */
.Pi-right-panels {
  flex: 1; /* Takes up 1/4 of the space */
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Pi-readmission-risk,
.Pi-allergies,
.Pi-medication {
  background-color: #fafafa;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.Pi-risk-level,
.Pi-allergy-item,
.Pi-med-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.Pi-risk-level:last-child,
.Pi-allergy-item:last-child,
.Pi-med-item:last-child {
  border-bottom: none;
}

/* add patient form */

.CPM-patient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.CPM-add-patient-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.CPM-add-form {
  margin-bottom: 1rem;
}

.CPM-add-form label {
  display: block;
  margin-bottom: 0.5rem;
}

.CPM-add-form input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.CPM-add-submit {
  margin: 10px 0;
  padding: 10px;
  width: 150px;
  background-color: #9a6cb4;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 70px;
}

.CPM-add-cancel {
  margin: 10px 0;
  padding: 10px;
  width: 100px;
  background-color: #e39fa9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}
