
.consultant-sidebar {
    width: 200px;
    background-color: #9a6cb4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    position: fixed; 
    top: 0;
    left: 0;
    height: 100vh; 
    z-index: 1000; 
}

.consultant-sidebar-logo {
    font-size: 24px;
    color: white;
    margin-bottom: 30px;
}

.consultant-sidebar-logo img {
    border-radius: 50%;
    width: 160px;
    height: 160px;
}

.consultant-sidebar-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.consultant-sidebar-item {
    width: 80%;
    padding: 20px 0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    margin-left: 70px;
    margin-bottom: 10px;
    font-size: 30px;
}

.consultant-sidebar-item:hover {
    color: #7c459c;
}

.logout-button {
    display: inline-flex; 
    align-items: center;
    background-color: transparent; 
    border: none;
    color: #ffffff; 
    cursor: pointer;
    padding: 15px 30px; 
    font-size: 18px;
    margin-left: -20px;
    border-radius: 25px;
    margin-bottom: 15px;
    margin-top: 150px; 
  }

  .logout-button svg {
    margin-right: 10px; 
    color: #ffffff;
  }
