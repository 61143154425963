/* appointmentassistant.css */

.appointment-assistant-container {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .appointment-main-container {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffffe5;
    border-radius: 50px 0 0 50px;
  }

  .appointment-assistant-top-search {
    position: relative;
    display: flex;
    align-items: center;
    width: 30%; 
    border-radius: 10px !important;
    margin-left: 10px;
  }

  .appointment-assistant-search-input {
    border: 2px solid #ccc; 
    border-radius: 5px; 
    width: 100%; 
    height: 30px;
    text-indent: 35px;
    color: #042440;
  }

  .assistant-search-icon{
    position: absolute;
    left: 5px;
    font-size: 16px;
    color: #042440;
  }

  .appointment-assistant-top-search input.appointment-assistant-search-input {
    border: 2px solid #ffffff; 
    border-radius: 20px; 
    width: 98%; 
    margin-left: -10px;
  }

  
  .appointment-assistant-content-container {
    display: flex;
    flex-direction: column;
  }
  
  .appointment-assistant-content-section h2{
    color: #040404;
    margin-left: 10px;
    margin-top: 50px;
  }

  .appointment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensures the container takes full width */
  }
  
  .sort-by-container {
    display: flex;
    align-items: center;
    white-space: nowrap; 
    margin-top: 40px;
  }
  
  .sort-by-container label {
    margin-right: 8px; /* Adds some space between the label and the dropdown */
  }
  
  .sort-by-container select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    width: 210px;
  }
  


/* upcoming */

.upcoming-appointments-container {
  width: 100%; 
  height: 700px; 
  overflow-y: auto; 
  margin-top: 10px;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.upcoming-appointments-container::-webkit-scrollbar {
  display: none; 
}

.upcoming-appointments-table {
  width: 100%;
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.upcoming-appointments-table th {
  background-color: #9a6cb4;
  font-weight: bold;
  color: #ffffff;
  padding: 12px 15px;
  text-align: left;
}

.upcoming-appointments-table td {
  padding: 12px 15px;
  color: #333;
  text-align: left;
  background-color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}

.upcoming-appointments-table tr:nth-child(even) td {
  background-color: #f9f9f9; 
}

.upcoming-appointments-table td button {
  padding: 5px 10px;
  background-color: transparent;
  color: #9a6cb4;
  border: none;
  cursor: pointer;
}

.upcoming-appointments-table td button:hover {
  text-decoration: underline;
}
