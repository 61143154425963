/* Container */
.UM-container {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* Main Section */
.UM-main-section {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.UM-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 1280px;
  margin-top: -20px;
}

.UM-user-profile {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 30px;
}

.UM-user-profile h1 {
  margin: 0; /* Remove default margin */
  font-size: 18px; /* Adjust the size as needed */
  margin-right: 70px;
}

.UM-user-profile p {
  margin: 0;
  font-size: 14px;
  color: #666;
  margin-right: 70px;
}

.UM-user-profile img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 290px;
  margin-top: -45px;
}

/* Filter Options */
.UM-filter-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.UM-filter-container {
  display: flex;
  align-items: center;
  margin-top: -50px;
  margin-right: 40px;
}

.UM-filter-container label {
  margin-right: 8px; /* Adjust the spacing between the label and select */
  margin-top: 8px;
}

.UM-filter-container select {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 150px;
}

.UM-toggle-select {
  display: flex;
  align-items: center;
  margin-left: 180px;
  margin-top: -50px;
}

.UM-toggle-select label {
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
}

.UM-total-users {
  font-weight: bold;
  color: #ccc;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
}

.UM-select {
  width: 150px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* User Table */
.UM-user-table-container {
  width: 100%; 
  height: 600px; 
  overflow-y: auto; 
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 8px;
  border-spacing: 0 15px;
}

.UM-user-table-container::-webkit-scrollbar {
  display: none; 
}

.UM-user-table {
  width: 95%;
  border-collapse: separate;
  margin-top: 10px;
  margin-left: 40px;
  border-radius: 8px;
  border-spacing: 0 15px;
  table-layout: auto; 
}

.UM-user-table thead {
  background-color: #ffffff;
}

.UM-user-table td {
  padding: 12px 15px;
  color: #333;
  text-align: left;
  background-color: #fff; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
  word-wrap: break-word; 
}

.UM-user-table th {
  background-color: #9a6cb4 !important;
  font-weight: bold;
  color: #ffffff;
  padding: 12px 15px;
  text-align: left;
}

.UM-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  object-fit: cover; /* Ensures the image covers the area without stretching */
}

.UM-user-table th:nth-child(1), 
.UM-user-table td:nth-child(1),
.UM-user-table th:nth-child(2), 
.UM-user-table td:nth-child(2),
.UM-user-table th:nth-child(3), 
.UM-user-table td:nth-child(3) {
  text-align: center;
}


/* Operation Buttons */
.UM-operation-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 25px; /* Adjust icon size */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.UM-delete-btn {
  color: #7c459c;
}

.UM-operation-btn {
  color: #7c459c;
}

.UM-add-btn{
  background-color: #9a6cb4;
  color: white;
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-top: -40px;
}

.UM-add-btn:hover{
  background-color: #e39fa9;
  color: #333;
}

.UM-logs-btn{
  background-color: #9a6cb4;
  color: white;
  width: 150px;
  height: 40px;
  border-radius: 30px;
  border: none;
  margin-top: -40px;
  margin-left: -170px;
}

.UM-logs-btn:hover{
  background-color: #e39fa9;
  color: #333;
}



/* Type Buttons */
.UM-type-buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 50px;
  margin-left: 40px;
}

.UM-type-button {
  padding: 20px 70px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  color: #040404;
  font-size: 18px;
}

.UM-type-button.active {
  border-bottom: 3px solid #e39fa9; /* Add a line below the button */
  color: #040404; /* Optional: Change the text color to match the line */
}

.UM-type-button:hover {
  background-color: #7c459c;
  color: #ffffff;
}

/* View Label */
.UM-view-label {
  margin-bottom: 10px;
  text-align: left;
  margin-top: 50px;
  margin-left: 40px;
}

.UM-view-label h2 {
  font-size: 30px;
  font-weight: bold;
  color: #4a4a4a;
  margin-top: 10px;
}

.UM-status-select {
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.UM-status-select:focus {
  border-color: #007bff;
  outline: none;
}

.UM-status-select option {
  padding: 5px;
  background-color: #fff;
  color: #333;
  width: 250px;
}

/* add patient form */

.UM-patient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.UM-add-patient-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.UM-add-form {
  margin-bottom: 1rem;
}

.UM-add-form label {
  display: block;
  margin-bottom: 0.5rem;
}

.UM-add-form input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.UM-add-submit {
  margin: 10px 0;
  padding: 10px;
  width: 150px;
  background-color: #9a6cb4;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 70px;
}

.UM-add-cancel {
  margin: 10px 0;
  padding: 10px;
  width: 100px;
  background-color: #e39fa9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}
