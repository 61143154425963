.FP-welcome-message {
  font-size: 80px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 50px;
  margin-top: -50px;
  margin-left: 100px;
  line-height: 80px;

}

.FP-back-button {
  position: absolute;
  top: 20px;
  left: 40px;
  color: #fff;
  font-size: 50px;
  text-decoration: none;
  z-index: 10; /* Ensure it appears above other elements */
}

.FP-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #7c459cbc !important; /* Adjust the color and opacity as needed */
  z-index: -1;
}

.FP-outer-container {
  padding: 200px;
  position: relative;
  z-index: 0;  
}

.FP-background{
  height: 550px;
}

.FP-outer-container .background-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  z-index: -1;
}

.forgot-pass-text {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-top: -20px;
  margin-left: -1150px;
  margin-bottom: 50px
}

.forgot-password-container {
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(67, 25, 92, 0.8);
  padding: 50px; /* Adjusted padding */
  width: 400px; /* Set a fixed width */
  margin-left: 100px;
  background-color: #ffffff5e;
}

.FP-button {
  padding: 10px 180px !important;
  background-color: #E39FA9;
  color: #040404;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-left: -15px;
  font-size: 12px;
}

.FP-button:hover {
  background-color: #7c459cbc;
  color: #ffffff;
}

.FP-error-message {
  color: #E39FA9;
  margin-left: 100px;
  margin-bottom: 40px;
  margin-top: -40px;
}

.success-message{
  font-size: 12px;
  text-align: right;
  margin-top: 15px;
  color: #ffffff;
}

.FP-form-group {
  position: relative;
  margin-bottom: 20px;
  margin-left: -18px;
}

.FP-form-input {
  padding: 10px;
  border: transparent !important;
  font-size: 16px;
  margin-top: 5px;
  background-color: white !important;
  color: #042440;
  width: 100%;
  transition: border-color 0.3s ease;
  border-radius: 5px !important;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.FP-form-input:focus ~ .FP-form-label,
.FP-form-input:not(:placeholder-shown) ~ .FP-form-label {
  top: -20px; /* Move label up when input is focused or has content */
  font-size: 12px; /* Shrink font size when label moves up */
  color: #042440; /* Change label color when active */
}

.FP-form-label {
  position: absolute;
  top: 20px; /* Initial position to start above the input */
  left: 20px;
  color: #042440;
  font-size: 16px; /* Start with a smaller font size */
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  pointer-events: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}