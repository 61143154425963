/* Sidebar */
.appointmentConsultant-dashboard {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* Main Content */
.appointmentConsultant-main {
  flex: 1;
  padding: 20px;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.appointmentConsultant-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-right: 30px; 
}

.appointmentConsultant-notificationIcon {
  margin-right: 2rem; /* Space between the icon and the user info */
  font-size: 1.5rem; /* Adjust size of the icon */
  color: #7c459c; /* Adjust color if needed */
  margin-top: 2px;
}

.appointmentConsultant-headerUser {
  display: flex;
  flex-direction: column; 
  margin-right: 20px; 
  text-align: right;
}

.appointmentConsultant-headerUser h1 {
  margin: 0; 
  font-size: 18px;
}

.appointmentConsultant-headerUser p {
  margin: 0; 
  font-size: 14px; 
  color: #666;
}

.appointmentConsultant-headerImage img {
  height: 40px; 
  width: 40px;
  border-radius: 50%; 
}

.appointmentConsultant-headerUserImage {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.appointmentConsultant-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  margin-left: 50px;
}

.appointmentConsultant-breadcrumb {
  margin: 20px 0;
  font-size: 16px;
  margin-left: 50px;
}

.appointmentConsultant-breadcrumb .breadcrumb-active {
  color: #7c459c; 
}

.appointmentConsultant-breadcrumb a {
  text-decoration: none;
  color: #040404; 
  font-size: 20px;
  font-weight: bold;
}

.appointmentConsultant-breadcrumb span {
  margin: 0 5px;
}

/* Appointments */

.appointmentConsultant-tabs {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons if needed */
  margin-left: 40px;
  margin-top: 50px;
}

.appointmentConsultant-tab {
  padding: 10px 20px;
  background-color: #f4f4f4;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.appointmentConsultant-tab.active {
  background-color: #e39fa9;
  color: white;
  font-size: 14px;
}

.appointmentConsultant-addAppointmentBtn {
  display: flex;
  align-items: center;
  color: #040404;
  background: none;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  margin-top: -35px;
  margin-left: 1200px;
  margin-bottom: 20px;
  white-space: nowrap;
}

.appointmentConsultant-appointments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width: 1500px;
  height: 700px;
}

.appointmentConsultant-addAppointmentBtn svg {
  margin-right: 8px; 
}


.appointmentConsultant-appointmentList {
  margin-bottom: 20px;
  overflow-y: scroll; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.appointmentConsultant-appointmentList::-webkit-scrollbar {
  display: none; 
}


.appointmentConsultant-appointmentItem {
  width: 1300px;
  padding: 5px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border-radius: 15px;
  margin-left: 120px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.appointmentConsultant-detail {
  flex: 1; /* Allow the detail blocks to grow and fill available space */
  margin-right: 20px; /* Space between each detail block */
  margin-bottom: 20px; /* Space between rows of details if they wrap */
}

.appointmentConsultant-detailContent {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.appointmentConsultant-label {
  font-weight: bold;
  color: #444;
  font-size: 14px;
  margin-bottom: 5px; 
}

.appointmentConsultant-text {
  font-size: 18px;
  color: #040404; 
}

.appointmentConsultant-action {
  margin-left: auto; 
}

.appointmentConsultant-statusSelect {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 50px !important;
  font-size: 14px;
  background-color: #e39fa9 !important;
  width: 150px;
  color: #040404;
  margin-right: 20px;
  text-align: center;
}

/* form */
.appointmentConsultant-appointmentForm {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0.9, 0.9);
  z-index: 1000; 
  width: 400px;
  height: 550px;
}

.appointmentConsultant-appointmentForm h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.appointmentConsultant-appointmentForm input,
.appointmentConsultant-appointmentForm select {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px !important;
  font-size: 16px;
  font-family: 'Lucida Sans', sans-serif;
  background-color: white; 
}

.appointment-select {
  width: 100%; 
  border: 1px solid #ccc !important; 
  background-color: white;
}

.appointment-add-button {
  margin: 10px 0;
  padding: 10px;
  background-color: #9a6cb4;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.appointment-cancel-button {
  margin: 10px 0;
  padding: 10px;
  background-color: #e39fa9;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: -5px;
}


