/* Container Background */
.user-profile-container {
  position: relative; /* Ensure this is added */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  width: 100%; 
  height: 100vh; 
  background-color: #ffffff !important;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px; 
  overflow: visible; 
}

.user-profile-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 19vh;
  background-color: #9a6cb4; 
  z-index: 0; 
}



.user-profile-back-btn {
  position: fixed;
  top: 20px; 
  left: 20px; 
  color: #042440; 
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.user-profile-back-btn:hover {
 color: #7c459c;
}

.user-profile-back-icon {
  margin-right: 8px; 
}

/* left section */
.left-section {
  background-color: #ffffff;
  height: 100vh;
  width: 18%; /* Adjust based on your design */
  margin-bottom: 50px;
  border: 2px solid #fbfbfb;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin-left: 0 !important;
  z-index: 1;
}


.left-section h2 {
  margin-top: 70px;
  font-size: 24px;
  color: #042440;
  margin-left: -170px;
}

.left-section p {
  margin-top: 10px;
  font-size: 16px;
  color: #042440d9;
  margin-left: -150px;
}

.settings-button-container {
  margin-top: 20px; 
  margin-left: -100px;
}

.UP-icon {
  color: #9a6cb4; 
  font-size: 24px; 
  margin-right: 8px; 
  transform: translateY(4px);
}

.settings-btn {
  display: block; 
  width: 100%; 
  padding: 10px; 
  margin-bottom: 10px;
  background-color: transparent;
  color: #042440; 
  border: none; 
  cursor: pointer; 
  font-size: 16px; 
  text-align: left;
  margin-left: 20px;
}

.settings-btn:hover {
  background-color: #e8e8e8; 
  border-left: 5px solid #E39FA9;
}


/* Left Container for Profile Picture */
.user-profile-left-container {
  position: fixed; 
  height: 400px; 
  width: 350px;
  top: 50px; 
  left: 1370px; 
  margin: 0;
  transition: all 0.3s ease; 
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 5px;
}

.user-profile-image-input{
  display: trans;
}

/* Profile Image Container */
.user-profile-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile-image-border {
  width: 250px;
  height: 250px;
  border: 2px solid #042440;
  border-radius: 50%;
  margin-top: 0px;
  margin-left: 40px;
  object-fit: cover;

}

.user-profile-image-header {
  text-align: left;
  margin-bottom: 20px; 
  margin-left: 10px;

}

.user-profile-image-title {
  font-size: 24px;
  color: #042440;
  margin-bottom: 10px; 
  margin-left: 20px;
}

.user-profile-image-description {
  margin-top: -20px !important;
  font-size: 16px;
  color: #042440 !important;
  margin-left: 0px !important;
  padding: 20px;
}

.user-profile-upload-button {
  position: absolute;
  top: 120px; 
  left: 260px;
  background-color: transparent;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; 
  color: #042440 !important;
  font-size: 30px;
  z-index: 10;
}

.user-profile-upload-button:hover {
  color: #E39FA9;
}

.user-profile-image-input {
  display: none;
}

/* Right Container for Personal Information */
.user-profile-right-container {
  padding: 20px;
  background-color: #ffffff;
  width: 40%; 
  height: 80%; 
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  z-index: 1;
  margin-left: 100px;
}

/* Align Right Container Text */
.user-profile-right-container h2 {
  text-align: left;
  margin-top: 0px;
  font-size: 24px;
  color: #042440;
  margin-left: 30px;
}

.user-profile-right-container p {
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  color: #042440d9;
  margin-left: 30px;
}

.user-profile-items-wrapper{
  margin-top: -25px;
}

.user-profile-divider-wrapper {
  position: relative;
  width: 100%;
  margin: 20px 0; 
  margin-top: 45px;
  margin-bottom: 10px; 
  padding-bottom: 40px;
  
}

.user-profile-divider-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 2px solid #9a6cb4; /* Divider color */
  transform: translateY(-50%);
}

.UP-divider-text-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff; /* Background color behind the text */
  padding: 0 10px;
  font-size: 16px;
  color: #9a6cb4; /* Text color */
}

.user-profile-item {
  display: flex;
  align-items: center;
  margin: 10px 0; 
  margin-left: 70px;
  width: 600px;
}

.user-profile-item label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px; 
  width: 150px; 
}

.user-profile-detail {
  padding: 12px;
  border: 1px solid #042440;
  background-color: #ffffff;
  flex: 1; 
  width: 400px;
 
}

.user-profile-divider {
  position: relative;
  width: 100%;
  margin: 20px 0; /* Adjust spacing as needed */
  margin-top: 40px;
}

.user-profile-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 2px solid #9a6cb4; /* Divider color */
  transform: translateY(-50%);
}

.UP-divider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff; /* Background color behind the text */
  padding: 0 10px;
  font-size: 16px;
  color: #9a6cb4; /* Text color */
}

.user-profile-details label {
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px; 
  width: 150px; 
}

/* File Input */
.user-profile-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Buttons */
.user-profile-edit-buttons {
  float: right;  
  margin-right: 20px;
}

.user-profile-edit-btn {
  padding: 8px 150px;
  background-color: #E39FA9;
  color: #fff;
  border: none !important;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  margin-top: 40px;
}

.user-profile-edit-btn:hover {
  background-color: #7c459c;
}

/* Buttons */
.user-profile-button-group {
  display: flex;
  justify-content: center; 
  position: fixed; 
  bottom: 100px; 
  left: 0;
  right: 200px; 
  margin-top: 20px; 
  background-color: transparent;
  padding: 10px 0; 
  box-shadow: none; 
}

.user-profile-save-btn,
.user-profile-cancel-btn {
  padding: 10px 20px; 
  background-color: #9a6cb4; 
  color: #fff; 
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px; 
  width: 300px; 
  text-align: center;
  display: flex;
  align-items: center; 
  justify-content: center; 
  white-space: nowrap;
  min-width: 100px; 
}

.user-profile-save-btn:hover,
.user-profile-cancel-btn:hover {
  background-color: #7c459c; 
}

.user-profile-cancel-btn {
  background-color: #E39FA9; 
}





/* Input Fields */
.user-profile-details {
  color: #ffffff;
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.user-profile-input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-profile-input-group label {
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px; 
  width: 150px; 
}

.user-profile-input {
  padding: 15px 15px;
  border-radius: 0px !important;
  border: 1px solid #042440 !important;
  background-color: #ffffff !important;
  width: 400px;
}

/* password setting */

.CP-divider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff; /* Background color behind the text */
  padding: 0 10px;
  font-size: 16px;
  color: #9a6cb4; /* Text color */
}

.change-pass-divider {
  position: relative;
  width: 100%;
  margin: 20px 0; /* Adjust spacing as needed */
  margin-top: 25px;
  margin-left: 0px;
  padding-bottom: 30px;
}

.change-pass-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 2px solid #9a6cb4; /* Divider color */
  transform: translateY(-50%);
}

.CP-user-profile-details {
  color: #ffffff;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.cp-error-message{
  font-size: 16px; /* Adjust font size as needed */
  margin-top: 10px; /* Space above the error message */
  margin-bottom: 10px; /* Space below the error message */
  padding: 10px; /* Padding around the message */
  border-radius: 4px; 
}

/* toggle */ 

.notification-settings{
  margin-top: 70px;
  margin-left: 50px;
}
.toggle-container {
  display: flex;
  align-items: center;
  margin: 10px 0; 
  padding-top: 20px;
}

.toggle {
  display: flex;
  align-items: center; 
  position: relative;
}

.toggle input {
  display: none;
}

.slider {
  position: relative;
  cursor: pointer;
  width: 34px; 
  height: 20px; 
  background-color: #ccc; 
  border-radius: 34px; 
  transition: background-color 0.4s;
  margin-right: 10px; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px; 
  left: 2px; 
  bottom: 2px; 
  background-color: white; 
  border-radius: 50%; 
  transition: transform 0.4s;
}

input:checked + .slider {
  background-color: #9a6cb4; 
}

input:checked + .slider:before {
  transform: translateX(14px); 
}

.toggle-label {
  white-space: nowrap; 
}

.user-profile-input.verified-status {
  padding: 15px 15px;
  border-radius: 0px !important;
  border: 1px solid #042440 !important;
  background-color: #ffffff !important;
  width: 400px;
  margin-left: 0;
}

/* prc verify */

.prc-verification-message {
  font-size: 14px;
  color: #042440 !important; 
  margin-right: -10px;
  font-weight: bold;
  position: relative;  
  left: -30px; 
}

.prc-verify-button {
  background-color: #9a6cb4;
  color: white;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  margin-top: 10px;
}

.prc-verify-button:hover {
  background-color: #E39FA9; /* Darker green on hover */
}

