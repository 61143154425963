.post-skeleton {
  position: relative;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff30;
  /* display: flex;
  align-items: flex-start; */
  height: 150px;
  width: 92.5%;
}

.right-col {
  flex: 1;
  margin-left: 1rem;
}

.post-author-skeleton {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
}

.post-content-skeleton {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(128, 128, 128, 0.472);
  margin-top: 1rem;
}

.post-interaction-skeleton {
  margin-top: 1rem;
  border-top: 1px solid rgba(128, 128, 128, 0.472);
  padding-top: 1rem;
}

.skeleton {
  animation: skeletonLoading 1s linear infinite alternate;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

/* baseColor="#0cbfdf45" highlightColor="#c8fdff4e" */
@keyframes skeletonLoading {
  0% {
    background-color: #f5f5f5;
  }

  100% {
    background-color: #e2e2e2;
  }
}
