.landingpage-assistant-container {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.landingpage-assistant-main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.landingpage-assistant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.landingpage-assistant-header h1{
  margin-left: 40px;
  margin-top: 40px;
}

.assistant-notification-button:hover {
  color: #e39fa9;
}

.landingpage-assistant-user-profile {
  display: flex;
  flex-direction: column; 
  margin-right: 80px; 
  text-align: right;
  margin-top: 30px;
}

.landingpage-assistant-user-profile h1 {
  margin: 0; /* Remove default margin */
  font-size: 18px; /* Adjust the size as needed */
  margin-right: 70px;
}

.landingpage-assistant-user-profile p {
  margin: 0; 
  font-size: 14px; 
  color: #666;
  margin-right: 70px;
}

.landingpage-assistant-user-profile img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 290px;
  margin-top: -45px;
}


.landingpage-assistant-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 columns */
  grid-template-rows: auto; /* Adjusts based on content */
  gap: 20px; /* Space between grid items */
  padding: 20px;
}


.landingpage-assistant-dashboard-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 300px;
}

/* Patients */

.landingpage-assistant-patients {
  width: 300px;
  height: 300px;
  margin-left: 40px;
  background: #e39fa9;
}

.landingpage-assistant-patients h2{
  color: #040404;
  margin-top: 10px;
  margin-left: 10px;
}

.landingpage-assistant-pie-chart {
  position: relative;
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
  margin-left: 50px;
  margin-top: 30px;
}

.landingpage-assistant-pie-chart .chartjs-render-monitor {
  position: absolute;
  top: 0;
  left: 0;
}

.landingpage-assistant-pie-chart-label {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  background-color: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  font-weight: bold;
  color: #7c459c;
  z-index: 1; /* Ensure label is above the chart */
  line-height: 100px; /* Center text vertically */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
}

/* End of Patients */

/* Appointment */
.landingpage-assistant-total-appointment {
  width: 300px;
  height: 300px;
  margin-left: -1160px;
}

.landingpage-assistant-total-appointment h2{
  color: #7c459c;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
}

.landingpage-assistant-chart {
  width: 100%;
  height: 500px; /* Adjust as needed */
}

/* End of Appointment */

/* Recent Appointments */

.landingpage-assistant-recent-appointments {
  height: 660px;
  width: 700px;
  margin-left: 840px;
  margin-top: -650px;
  overflow-y: scroll; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  z-index: 1;
}

.landingpage-assistant-recent-appointments::-webkit-scrollbar {
  display: none; 
}


.landingpage-assistant-recent-appointments h2{
  color: #7c459c;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
}

.assistant-profile-icons {
  display: flex;
  align-items: center;
  margin-right: -1200px;
  z-index: 1001; 
  margin-top: 30px;
}

.assistant-profile-icon,
.assistant-notification-icon {
  font-size: 30px;
  color: #7c459c; 
}

.assistant-profile-icon {
  margin-right: 10px; 
}

.assistant-notification-icon {
  margin-left: 10px; 
}

.landingpage-assistant-month-selector {
  margin-top: -30px; /* Adjust spacing as needed */
  margin-left: 500px;
}

.landingpage-assistant-month-selector select {
  font-size: 16px; /* Match font size with label */
  padding: 8px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background */
  width: 200px; /* Adjust width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.landingpage-assistant-month-selector select:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none; /* Remove default outline */
}

.landingpage-assistant-patients-list {
  display: flex;
  flex-direction: column;
}

.landingpage-assistant-patient-date-group {
  margin-bottom: 20px;
}

.appointment-date-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #a39f9f;
}

.landingpage-assistant-patient-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
}

.landingpage-assistant-patient-item:last-child {
  border-bottom: none;
}

.patient-picture {
  margin-right: 15px;
}

.patient-picture img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.patient-details {
  flex: 1;
}

.patient-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.branch-location {
  font-size: 14px;
  color: #777;
}

.appointment-date {
  font-size: 14px;
  color: #555;
}

/* End of Recent Appointments */



/* Grid Layout */

.landingpage-assistant-patients {
  grid-column: 1; /* First column */
  grid-row: 1; /* First row */
}

.landingpage-assistant-library {
  grid-column: 1; /* Same column as "Expenses" */
  grid-row: 2; /* Directly below "Expenses" */
}

.landingpage-assistant-total-appointment {
  grid-column: 2; /* Second column */
  grid-row: 1; /* Top-right position */
}

/* End of Grid Layout */

.assistant-greeting-section {
  display: flex;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
  height: 150px;
  width: 700px;
  margin-top: 80px;
  align-items: center;
  justify-content: space-between; 
  padding: 30px;
  background-color: white; 
}

.assistant-greeting-text {
  max-width: 50%; /* Restricts the text width */
  margin-left: 20px;
}

.assistant-greeting-text h2 {
  margin: 0;
  font-size: 26px;
  color: #333;
}

.assistant-greeting-text h3 {
  margin: 5px 0 0 0;
  font-size: 30px;
  color: #7c459c;
}

.assistant-greeting-text p {
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #666;
}

.assistant-greeting-image {
  max-width: 100%;
  margin-top: -70px;
  margin-right: 20px;
}

.assistant-greeting-image img {
  width: 350px; 
  height: auto;
  border-radius: 8px; /* Optional: add a border-radius to the image */
}
