.landingpage-assistant-sidebar {
  width: 200px;
  background-color: #9a6cb4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: fixed; /* Ensure sidebar stays in place */
  top: 0;
  left: 0;
  height: 100vh;
  }
  
  .landingpage-assistant-sidebar-logo img {
    border-radius: 50%;
    width: 160px;
    height: 160px;
  }
  
  .landingpage-assistant-sidebar-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    margin-left: 20px;
  }
  
  .landingpage-assistant-sidebar-item {
    width: 80%;
    padding: 20px 0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 30px;
  }
  
  .landingpage-assistant-sidebar-item:hover {
    color: #7c459c;
  }

  .assistant-logout-button {
    display: inline-flex; 
    align-items: center;
    background-color: transparent; 
    border: none;
    color: #ffffff; 
    cursor: pointer;
    padding: 15px 30px; 
    font-size: 18px;
    margin-left: 10px;
    border-radius: 25px;
    margin-bottom: 15px;
    margin-top: 150px; 
  }

  .assistant-logout-button svg {
    margin-right: 10px; 
    color: #ffffff;
  }
