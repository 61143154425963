.manage-bellytalk-container {
  display: flex;
  height: 100vh;
  width: 89%;
  margin-left: 200px;
  background-color: #9a6cb4;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.manage-bellytalk-main-container {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffffe5;
  border-radius: 50px 0 0 50px;
}

.manage-bellytalk-main-container h2{
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
  color: #333;
  margin-top: 20px;
}

.manage-bellytalk-category-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, 1fr); /* 2 rows */
  gap: 20px;
  margin-bottom: 40px;
}

.manage-bellytalk-category-card {
  padding: 30px;
  border-radius: 15px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.manage-bellytalk-category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}


/* Additional categories */
/* Use this to increase specificity */
.manage-bellytalk-category-card.health {
  background-color: #9DC3E2; 
}


.manage-bellytalk-category-card.finance {
  background-color: #e39fa9   ; 
}

.manage-bellytalk-category-card.parenting {
  background-color: #9a6cb4af ; 
}

.manage-bellytalk-category-card.essentials {
  background-color: #e39fa9; 
}

.manage-bellytalk-category-card.exercise {
  background-color: #9a6cb4af; 
}

.manage-bellytalk-category-card.labor {
  background-color: #9DC3E2 ; 
}

.manage-bellytalk-chart {
  margin-top: 50px;
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.chart-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Modal Styles */
.MuiDialog-paper {
  width: 450px; 
  padding: 20px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 280px;
}

.dialog-title-text {
  font-size: 20px !important; 
  color: #7c459c; 
  border-left: 5px solid #7c459c;
  text-indent: 10px;
}

.MuiDialogContent-root {
  text-align: left;
  margin-top: 20px;
  line-height: 1.5 !important;
  margin-left: 15px;
}

.dialog-close-button {
  position: absolute; 
  right: -25px; 
  top: -40px; 
  color: #7c459c; 
}

.dialog-content {
  padding: 16px; 
  background-color: #ffffff; 
}


.dialog-button-container {
  display: flex;
  gap: 25px; /* Adjusts space between buttons */
  align-items: center;
  flex-wrap: nowrap;
}

.dialog-download-button {
  color: #7c459c !important;
  white-space: nowrap; /* Prevents line breaks */
}


.dialog-close-button:hover,
.dialog-download-button:hover {
  background-color: rgba(124, 69, 156, 0.1); 
  transition: background-color 0.3s;
}

