.bellytalk-container {
  display: flex;
  background-color: #9a6cb4cd !important;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.bellytalk-main-content {
  flex: 1;
  overflow-y: auto;
  position: relative;
  margin: 0;
  background-color: #ffffff;
  margin-top: -20px;
  width: 85%;
  margin-left: 140px;
}

.bellytalk-main-content::-webkit-scrollbar {
  display: none;
}

.bellytalk-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  width: 100%;
  height: 100px;
}

.bellytalk-title-logo {
  font-size: 30px;
  font-weight: bold;
  color: #7c459c;
  margin-left: 200px;
}

.BT-back-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 100px;
  margin-right: -150px;
  color: #7c459c;
}

.bellytalk-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 600px;
}

.bellytalk-icon-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 30px;
}

.bellytalk-icon {
  font-size: 24px;
  margin-right: 10px !important;
  cursor: pointer;
  color: #e39fa9 !important;
}

.bellytalk-label {
  font-size: 16px;
  color: #603830;
  margin-right: 15px;
}

.bellytalk-search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  border-radius: 20px;
  margin-right: 200px;
}

.bellytalk-search {
  border-radius: 20px;
  border: 1px solid #042440;
  outline: none;
  text-indent: 40px;
  z-index: 1;
  height: 40px;
  width: 500px;
}

.bellytalk-search::placeholder {
  color: #042440;
}

.bellytalk-search-icon {
  position: absolute;
  left: 15px;
  font-size: 16px;
  color: #042440 !important;
  z-index: 1000;
}

/* share-box */

.content-container {
  display: flex;
  justify-content: space-between; /* Ensures content is spaced out */
}

.sharebox {
  position: relative;
  margin-top: 70px;
  margin-bottom: 20px;
  margin-left: 100px;
}

.sharebox-container {
  position: relative;
  width: 925px;
}

.sharebox .bellytalk-share-box-input {
  flex: 1;
  border-radius: 10px;
  background-color: #9a6cb4 !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin-top: -40px;
  height: 70px;
  width: 100%;
  text-indent: 30px;
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 15px;
}

.sharebox input::placeholder {
  color: #ffffff;
  font-size: 15px;
}

.pen-icon {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 2px;
}

.sharebox-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sharebox-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  position: relative;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  font-size: 16px;
}

.sharebox-back-button {
  font-size: 24px;
  color: #7c459c;
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 35px;
}

.sharebox-textarea {
  width: 100%;
  height: 100%;
  max-height: 200px;
  border-radius: 8px;
  border: none;
  margin-top: 10px;
  font-size: 16px;
}

.sharebox-textarea:focus {
  outline: none;
}

.sharebox-button {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #042440;
  cursor: pointer;
  border-radius: 5px;
}

.sharebox-title {
  font-size: 24px;
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.5;
  padding: 10px;
  border-bottom: 2px solid #7c459c;
}

.upload-button {
  display: flex;
  align-items: center;
  margin-top: 150px;
  background-color: none;
  border: solid 1px #333;
  border-radius: 10px;
  height: 50px;
}

.upload-button p {
  margin-left: 20px;
}

.custom-file-upload {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: none;
  margin-left: 300px;
  font-size: 30px;
}

.custom-file-upload svg {
  margin-right: 5px;
}

.image-preview {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.preview-image {
  max-width: 100%;
  max-height: 250px;
  object-fit: contain;
  border-radius: 10px;
  display: block;
}

.remove-image {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #040404;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensures the button is on top of the image */
}

.remove-image:hover {
  background-color: rgba(255, 255, 255, 1);
}

.post {
  margin-top: 20px;
}

.bt-post-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  width: 500px;
}

/* feed */

.bellytalk-feed {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 50px;
  width: 50% !important;
  margin-left: 150px !important;
}

.bellytalk-feed-item {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: flex-start;
}

.bellytalk-avatar-overlay {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
  object-fit: cover;
}

.bellytalk-post-content {
  margin-left: 40px;
  width: 95%;
}

.bellytalk-post-content h4 {
  margin: 5px 0;
  color: #7c459c;
}

.bellytalk-post-content p {
  margin: 5px 0;
}

.bellytalk-post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bellytalk-menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

.bellytalk-meatball-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 5px 0;
  width: 150px;
  z-index: 100;
}

.bellytalk-meatball-menu li {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease;
}

.bellytalk-meatball-menu li:hover {
  background-color: #f5f5f5;
}

.location-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Adjust margin as needed */
  color: #e39fa9;
}

.bellytalk-divider {
  border: 0;
  height: 1px;
  background: #e0e0e0;
  margin: 15px 0;
}

.bellytalk-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 550px;
}

.bellytalk-action-button {
  font-size: 20px;
  color: #9a6cb4;
  cursor: pointer;
  transition: color 0.3s ease;
  border: none;
  background-color: transparent;
  margin-top: 5px;
}

.bellytalk-action-button:hover {
  color: #e39fa9;
}

.bellytalk-action-icon {
  font-size: 20px;
  color: #9a6cb4;
  cursor: pointer;
  transition: color 0.3s ease;
}

.active {
  color: #e39fa9; /* Change color to pink when active */
}

.bellytalk-action-icon:hover {
  color: #e39fa9;
}

.bellytalk-reply-container {
  position: relative;
}

.bellytalk-reply-input {
  width: 60% !important;
  padding: 10px !important;
  border: none !important;
  margin-bottom: 10px !important;
  box-sizing: border-box !important;
  font-size: 14px !important ;
  background-color: transparent !important;
  z-index: 10;
  margin-top: -40px !important;
  position: absolute;
}

.bellytalk-comment {
  margin-bottom: 25px;
}

.comment-user-info {
  display: flex;
  align-items: flex-start;
}

.comment-avatar {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%; /* Rounded avatar */
  margin-right: 10px;
}

.comment-user-info h4 {
  margin: 0;
  font-size: 16px;
  display: inline;
}

.comment-user-info p {
  margin: 5px 0 0; /* Adjust spacing */
  font-size: 14px;
  line-height: 1.4;
}

/* filter */

.filter-section {
  position: absolute;
  width: 420px;
  left: 1100px;
  top: 0px;
}

.filter-container {
  background-color: #ffffff;
  height: 400px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-container label {
  display: block;
  margin: 5px 0;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filter-container input[type="checkbox"] {
  display: none;
}

.filter-container input[type="checkbox"] + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
}

.filter-container input[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border: 2px solid #7c459c;
  border-radius: 3px;
  background-color: white;
  transition: background-color 0.2s ease;
}

.filter-container input[type="checkbox"]:checked + label::before {
  background-color: pink;
  border-color: pink;
}

.filter-container input[type="checkbox"] + label::after {
  content: "";
  position: absolute;
  left: 14px;
  top: 43%;
  transform: translateY(-50%) rotate(45deg);
  width: 5px;
  height: 10px;
  border: solid #042440;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.filter-container input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/* edit post */

.bellytalk-edit-input {
  width: 80%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 16px;
  resize: vertical;
}

.bellytalk-edit-input:focus {
  outline: none;
}

.bellytalk-edit-save {
  background-color: #e39fa9;
  color: #333;
  border: none;
  padding: 10px 50px;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 380px;
  margin-bottom: 20px;
}

.bellytalk-edit-cancel {
  margin-left: 10px;
  background-color: #e39fa9;
  color: #333;
  border: none;
  padding: 10px 50px;
  font-size: 14px;
  border-radius: 5px;
}

.verified-icon {
  color: #6b95e5; /* Green color for the verified icon */
  margin-left: 4px; /* Space between name and icon */
}

.bt-dropdown-container {
  position: relative;
  display: inline-block;
}

.BT-profile-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #7c459c;
  position: absolute;
  right: 120px;
  font-size: 40px;
  top: -20px;
}

.bt-dropdown-menu {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.bt-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bt-dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.bt-dropdown-menu li:last-child {
  border-bottom: none;
}

.bt-dropdown-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
}

.bt-dropdown-menu li a:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.bt-dropdown-menu li span:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}